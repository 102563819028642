@import '../index.scss';

.loader{
    opacity: 1;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: $white;
    z-index: 30000;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    animation: animateLoaderOpacity 1s ease forwards 5.5s;

    &__line{
        position: relative;
        background-color: rgba(0, 0, 0, 0.050);
        height: 2px;
        width: 50%;
        display: flex;
        align-items: center;
        animation: animateLine 1.5s cubic-bezier(.95,0,.04,.99) forwards 4s, ;
        
        &__inner{
            width: 0%;
            height: 100%;
            background-color: $background;
            animation: animateInnerLine 4s cubic-bezier(.72,0,.23,1) forwards;
        }

        &::before, &::after{
            content: 'LOUIS MONY';
            position: absolute;
            bottom: 10px;
            left: 0;
            font-size: 12px;
            animation: animateLoaderOpacity 500ms ease forwards 3.5s;
        }

        &::after{
            content: 'FOLIO 2024';
            right: 0;
            left: initial;
        }
    }

    @keyframes animateInnerLine {
        0% {width: 0%;}
        100% {width: 100%;}
    }

    @keyframes animateLoaderOpacity {
        0% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }

    @keyframes animateLine {
        0% {
            height: 2px;
            width: 50%;
        }
        100% {
            height: 100%;
            width: 100%;
        }
    }
}