@import '../index.scss';

.slide-out-scnd{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: $background;
    z-index: 11000;
}

.slide-in, .slide-out {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: $white;
    z-index: 11000;
    display: flex;
    align-items: center;
    justify-content: center;

    p{
        color: $background;
        font-size: 24px;
        text-transform: uppercase;
        text-align: center;
     }
}
  