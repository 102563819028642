//FONT
@font-face {
  font-family: 'HumaneBold';
  src: url('./font/Humane-Bold.woff') format('woff'), /* Modern Browsers */
       url('./font/Humane-Bold.woff2') format('woff2'), /* Modern Browsers */
       url('./font/Humane-Bold.ttf') format('truetype'); /* Safari, Android, iOS */
  font-style: normal;
  font-weight: bold;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'HumaneExtraLight';
  src: url('./font/Humane-ExtraLight.woff') format('woff'), /* Modern Browsers */
       url('./font/Humane-ExtraLight.woff2') format('woff2'), /* Modern Browsers */
       url('./font/Humane-ExtraLight.ttf') format('truetype'); /* Safari, Android, iOS */
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'HumaneLight';
  src: url('./font/Humane-Light.woff') format('woff'), /* Modern Browsers */
       url('./font/Humane-Light.woff2') format('woff2'), /* Modern Browsers */
       url('./font/Humane-Light.ttf') format('truetype'); /* Safari, Android, iOS */
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'HumaneMedium';
  src: url('./font/Humane-Medium.woff') format('woff'), /* Modern Browsers */
       url('./font/Humane-Medium.woff2') format('woff2'), /* Modern Browsers */
       url('./font/Humane-Medium.ttf') format('truetype'); /* Safari, Android, iOS */
           font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'HumaneRegular';
  src: url('./font/Humane-Regular.woff') format('woff'), /* Modern Browsers */
       url('./font/Humane-Regular.woff2') format('woff2'), /* Modern Browsers */
       url('./font/Humane-Regular.ttf') format('truetype'); /* Safari, Android, iOS */
           font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'HumaneSemiBold';
  src: url('./font/Humane-SemiBold.woff') format('woff'), /* Modern Browsers */
       url('./font/Humane-SemiBold.woff2') format('woff2'), /* Modern Browsers */
       url('./font/Humane-SemiBold.ttf') format('truetype'); /* Safari, Android, iOS */
           font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'HumaneThin';
  src: url('./font/Humane-Thin.woff') format('woff'), /* Modern Browsers */
       url('./font/Humane-Thin.woff2') format('woff2'), /* Modern Browsers */
       url('./font/Humane-Thin.ttf') format('truetype'); /* Safari, Android, iOS */
           font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

//VARIABLE

$baseUnit : 24px;

$max-mobile: 768px;
$max_tablette: 1024px;

$white : #dcdcdc;
$background : #111111;
$maincolor: #FFE500;

//GENERAL

*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: Helvetica, sans-serif;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none; 

  &::-webkit-scrollbar {
    display: none;
  }
}

body{
  width: 100%;
  height: 100vh;
  background-color: $background;
  overflow-x: hidden;

  .transi{
    position: fixed;
    top: 0;
    left: 0;
    height: 0vh;
    width: 100vw;
    z-index: 11000;
    mix-blend-mode: normal;

    display: flex;
    
    &__line{
      width: 100%;
      height: 0;
      background-color: rgb(35, 35, 35);

      @media screen and (max-width: $max_mobile) {
        &:nth-child(odd) {
            display: none;
        }
      }
    }
  }

  .gsapMain{
    //transform-origin: center;
  }

  .animEnter{
    position: relative;
    &::before{
      content: 'Enter';
      position: absolute;
      text-transform: uppercase;
      font-family: 'Helvetica';
      font-size: 12px;
      transform: translate(-50%, -100%);
      bottom: -5px;
      left: 50%;
      color: $white;
      mix-blend-mode: difference;
      z-index: 10000;
      opacity: 0;
      transition: all 500ms cubic-bezier(.72,0,.23,1);
    }

    &:hover{
      &::before{
        opacity: 1;
        bottom: 0;
      }
    }
  }
}

p,h1,h2,h3,h3{
  margin:0;
  font-weight: 400;
}

a{
  text-decoration: none;
  color: inherit;
}

