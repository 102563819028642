@import '../index.scss';

@keyframes fadeInImg {
    from{
        opacity: 0;
        margin-top: 50px;
        clip-path: polygon(0 53%, 100% 95%, 100% 100%, 0% 100%);
    }
    to{
        opacity: 1;
        margin-top: 0px;
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    }
}

.work{
    background-repeat: no-repeat;
    background-position: 50%, 50%;
    background-attachment: fixed;
    background-size: cover;
    background-color: $background;

    &__spacer{
        height: 30vh;
        background-color: $background;
        
        @media screen and (max-width: $max_tablette) {
            height: 20vh;
        }
        @media screen and (max-width: $max_mobile) {
            height: 10vh;
        }
    }
    
    &__banner{
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;

        &__content{
            height: 100%;
            width: 100%;
            background-color: rgba(0, 0, 0, 0.3);
            color: $white;

            &__info{
                display: flex;
                gap: $baseUnit;
                position: absolute;
                bottom: 25vw;
                left: 2vw;
                width: fit-content;
                overflow: hidden;
                
                @media screen and (max-width: $max_tablette) {
                    flex-direction: column;
                    bottom: 30vw;
                }

                @media screen and (max-width: $max_mobile) {
                    flex-direction: column;
                    align-items: end;
                    right: 2vw;
                    left: auto;
                }
                
                &__stack, &__date, &__visit {
                    border: solid 2px $white;
                    padding: 15px;
                    border-radius: 100px;
                    font-size: 24px;
                    text-transform: uppercase;
                    width: fit-content;

                    @media screen and (max-width: $max_tablette) {
                        width: fit-content;
                        
                    }

                    @media screen and (max-width: $max_mobile) {
                        font-size: 16px;
                    }
                }

                &__visit{
                    background-color: $white;
                    color: $background;
                    position: relative;
                    transition: all 200ms linear;
        
                    @media screen and (min-width: $max_tablette) {
                        &:hover{
                            background-color: rgb(169, 169, 169);
                            border-color: rgb(169, 169, 169);
                        }
                    }
                }
                

            }

            h1{
                position: absolute;
                z-index: 100;
                bottom: 0;
                left: $baseUnit;
                display: flex;
                flex-direction: row;
                gap: 10px;
                margin-bottom: -5.5vw;
                cursor:default;

                @media screen and (max-width: $max_tablette) {
                    margin-bottom: -4.5vw;
                    
                    .charTitleWork{
                        font-size: 31vw;
                    }
                }

                @media screen and (max-width: $max_mobile) {
                    margin-bottom: -3vw;
                }
                .charTitleWork{
                    text-transform: uppercase;  
                    font-size: 32vw;
                    font-family: 'HumaneBold';  
                    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);

                    @media screen and (max-width: $max_tablette) {
                        font-size: 31vw;
                    }
                }
            }
        }
    }


    &__desc{
        padding: calc(100vw/12) calc((100vw/12));
        background-color: $background;
        display: flex;
        height: fit-content;
        min-height: 60vh;
        position: relative;

        @media screen and (max-width: $max_tablette) {
            flex-direction: column;
            gap: calc(2*$baseUnit);
            min-height: fit-content;
        }

        &__line{
            position: absolute;
            top: 40px;
            left: calc((100vw/12));
            width: calc(10*(100vw/12));
            height: 1px;
            opacity: .5;
            background-color: $white;

            @media screen and (max-width: $max_mobile) {
                top: 0px;
            }
        }

        &__text{
            width: calc(7*(100vw/12));
            @media screen and (max-width: $max_tablette) {
                width: 100%;
            }
            p{
                width: 100%;
                .word{
                    font-family: Helvetica;
                    color: $white;
                    font-size: 32px;

                    @media screen and (max-width: $max_tablette) {
                        font-size: 20px;
                    }
                }
            }
        }

        &__label{
            width: calc(3*(100vw/12));

            @media screen and (max-width: $max_mobile) {
                display: none;
            }
            p{
                font-family: Helvetica;
                color: $white;
                opacity: .5;
                font-size: 18px;
                width: 100%;
                
                @media screen and (max-width: $max_mobile) {
                    width: 100%;
                    font-size: 16px;
                    margin-bottom: 0;
                }
            }
        } 
    }

    &__gallery{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: $baseUnit;
        background-color: $background;

        &__container{
            width: calc(10*(100vw/12));
            height: fit-content;

            img{
                width: 100%;
                object-fit: cover;

                @media (prefers-reduced-motion: no-preference){
                    opacity: 0;
                    margin-top: 50px;
                    clip-path: polygon(0 53%, 100% 95%, 100% 100%, 0% 100%);
                    animation: fadeInImg linear forwards;
                    animation-timeline: view();
                    animation-range-start: 25vh;
                    animation-range-end: 75vh;
                }
            }
        }

        &__containerdouble{
            display: flex;
            gap: $baseUnit;
            height: fit-content;

            
            div{
                width: 50%;
                height: fit-content;
                
                img{
                    width: 100%;
                }
            }
            @media screen and (max-width: $max_tablette) and (orientation: portrait) {
                flex-direction: column;
                
                div{
                    width: 100%;
                }
            }
        }
    }
    
    &__footer{
        background-color: $background;
        color: $white;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        &__img{
            position: absolute;
            height: 50vh;
            width: calc((100% / 12) * 6);
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 10;
            display: flex;
            align-items: center;
            overflow: hidden;
            justify-content: center;
            clip-path: polygon(50% 50%, 50% 50%, 50% 50%, 50% 50%);
            transition: all 800ms cubic-bezier(.11,.75,.28,.95);
            filter: brightness(.7);
            background-color: rgb(87, 87, 87);

            img{
                width: 300%;
                height: 300%;
                transition: all 1200ms cubic-bezier(.11,.75,.28,.95);
            }
            
            @media screen and (max-width: $max_tablette) {
                clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

        }

        &__hover:hover ~ &__img {
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
          }

        &__hover{
            z-index: 100;
            cursor: pointer;
            position: relative;
            margin: auto;
            width: fit-content;
            height: 13vw;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            align-items: center;

            @media screen and (max-width: $max_tablette) {
                height: 18vw;
            }

            @media screen and (max-width: $max_mobile) {
                height: 28vw;
            }

            p{
                font-family: 'HumaneBold';
                text-transform: uppercase;
                font-size: 15vw;
                transform: translateY(0%);
                transition: all 500ms cubic-bezier(.15,.77,.29,.92);

                @media screen and (max-width: $max_tablette) {
                    font-size: 20vw;
                }
                @media screen and (max-width: $max_mobile) {
                    font-size: 30vw;
                }
            }

            

            @media screen and (min-width: $max_tablette) {
                &:hover{
                    p{
                        transform: translateY(-100%);
                    }
                }
            }

            
        }
        &__link{
            position: absolute;
            left: $baseUnit;
            bottom: $baseUnit;
            text-transform: uppercase;
            font-size: 24px;
        }
    }
}