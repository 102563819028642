@import '../index.scss';

.About{
    z-index: 20000;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    color: $white;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: $max_tablette) {
       flex-direction: column;
       justify-content: left;
       overflow-y: scroll;
    }
    
    .Gsap_container{
        overflow: hidden;
    }

    .lineAbout{
        height: 1px;
        width: 100%;
        background-color: $white;
        margin: 24px 0 12px 0;
        opacity: .5;
    }

    &__content{
        padding: $baseUnit;
        width: 40%;
        height: 100vh;
        //position: relative;
        @media screen and (max-width: $max_tablette) {
            width: 100%;
            height: fit-content;
            
        }

        .close{
            position: absolute;
            right: $baseUnit;
            top: $baseUnit;
            cursor: pointer;
            z-index: 21000;

            &__hoverable{
                height: 14px;
                font-size: 10px;
                overflow: hidden;
                p{
                    font-family: Helvetica, sans-serif;
                    text-transform: uppercase;
                    font-size: 12px;
                    transform: translateY(0%);
                    transition: all 500ms cubic-bezier(.15,.77,.29,.92);
                    color: $white;
                    
                }
            }
    
            @media screen and (min-width: $max_tablette) {
                &:hover{
                    p{
                        transform: translateY(-100%);
                    }
                }
            }
        }

        h2{
            font-size: 5vw;
            text-transform: uppercase;
            @media screen and (max-width: $max_mobile) {
                font-size: 11vw;
            }
            @media screen and (max-height: 690px) {
                font-size: 3vw;
            }
        }

        p{
            font-family: Helvetica;
            font-size: 12px;
            text-transform: uppercase;
        }

        &__item{
            width: 100%;
            display: flex;
            align-items: start;
            justify-content: space-between;
            margin-bottom: 24px;
            @media screen and (max-height: 690px) {
                margin-bottom: 16px;
            }

            p{
                font-family: Helvetica;
                span{
                    opacity: .5;
                }
            }
        }

        
    }

    &__work{
        width: 40%;
        height: 100%;
        padding: $baseUnit $baseUnit 0 $baseUnit ;
        width: 40%;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: end;

        @media screen and (max-width: $max_tablette) {
            width: 100%;
            height: fit-content;
        }


        h2{
            font-size: 5vw;
            text-transform: uppercase;
            @media screen and (max-width: $max_mobile) {
                font-size: 11vw;
            }
            @media screen and (max-height: 690px) {
                font-size: 3vw;
            }
        }

        .linkable{
            cursor: pointer;
        }
        &__item{
            width: 100%;
            display: flex;
            align-items: start;
            justify-content: space-between;
            margin-bottom: 24px;

            @media screen and (max-height: 690px) {
                margin-bottom: 16px;
            }

            p{
                font-family: Helvetica;
                font-size: 12px;
                text-transform: uppercase;

                span{
                    opacity: .5;
                }
            }
        }
        ul{
            list-style-type: none;
            position: fixed;
            bottom: $baseUnit;
            left: $baseUnit;

            @media screen and (max-width: $max_tablette) {
                position: relative;
                left: 0;
                margin-top: calc($baseUnit*2);
                display: flex;
                gap: $baseUnit;
            }

            li{

                a{
                    font-family: Helvetica;
                    text-transform: uppercase;
                    font-size: 10px;
                    opacity: .5;

                    &:hover{
                        opacity: 1;
                    }

                    @media screen and (max-width: $max_mobile) {
                        font-size: 12px;
                    }
                }
            }
        }
    }

    
    
}