@import '../index.scss';

.cursor {
    width: 0px;
    aspect-ratio: 1/1;
    position: fixed;
    top: 0;
    left: 0;
    border-radius: 50%;
    pointer-events: none;
    z-index: 1000;
    background-color: rgb(48, 83, 197);
    //mix-blend-mode: difference;
    transition: all 200ms linear;
    display: flex;
    align-items: center;
    justify-content: center;


    @media screen and (max-width: $max_tablette) {
        display: none;
    }

    .cursor_p{
        overflow: hidden;
        font-size: 14px;
        color: $white;
        width: 0%;
        height: 0%;
        text-transform: uppercase;
        text-align: center;
    }
}

.ishovering {
    width: 100px;
    aspect-ratio: 1/1;
    position: fixed;
    top: 0;
    left: 0;
    border-radius: 50%;
    pointer-events: none;
    z-index: 1000;
    background-color: rgb(48, 83, 197);
    mix-blend-mode: normal;
    display: flex;
    align-items: center;
    justify-content: center;

    .cursor_p{
        width: 100%;
        height: fit-content;
    }

    @media screen and (max-width: $max_tablette) {
        display: none;
    }
}