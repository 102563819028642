@import '../index.scss';

.Home2 {
    width: 100%;
    height: 100vh;
    position: relative;
    overflow: hidden;

    &__Title{
        width: max-content;
        position: fixed;
        transform: translate(-50%, -43%);
        top: 50%;
        left: 50%;
        height: 13vw;
        z-index: 300;
        overflow: hidden;
        @media screen and (max-width: $max_tablette) {
            height: 28vw;
        }

        h2{
            
            font-size: 15vw;
            text-transform: uppercase;
            text-align: center;
            font-family: 'HumaneBold';
            color: $white;
            line-height: 13vw;
            cursor: pointer;
            transition: letter-spacing 500ms cubic-bezier(.01, .82, .23, 1), margin-right 500ms cubic-bezier(.01, .82, .23, 1);
        
            @media screen and (max-width: $max_tablette) {
                font-size: 30vw;
                line-height: 28vw;
            }
        }

        .current{
            &:hover{
                letter-spacing: 30px;
                margin-right: -30px;

                @media screen and (max-width: $max_tablette) {
                    letter-spacing: inherit;
                    margin-right: 0;
                }
            }
        }
    }

    &__ScrollScooter{
        width: $baseUnit;
        height: 50vh;
        position: fixed;
        transform: translate(0%, -50%);
        top: 50%;
        left: $baseUnit;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;

        @media screen and (max-width: $max_tablette) {
            display: none;
        }
        
        &__Graduation{
            width: 20%;
            height: 1px;
            background-color: $white;
            opacity: .3;

            &:first-child {
                width: 50%;
            }
            &:last-child {
                width: 50%;
            }
        }

        &__pointer{
            position: absolute;
            width: 20%;
            background-color: $white;
            transition: all 500ms cubic-bezier(.01, .82, .23, 1)
        }
    }
}

//SLIDER
.swiper-global{
    height: 100vh;
    padding-top: 25vh;

    .swiper-wrapper{
        transition-timing-function: cubic-bezier(.1,.67,.27,.92)
    }

    .swiper {
        width: calc((100% / 12) * 6);
        height: 50vh;
        z-index: 100;
        overflow: visible!important;

        @media screen and (max-width: $max_tablette) {
            width: calc((100% / 12) * 8);
        }
        @media screen and (max-width: $max_mobile) {
            width: calc((100% / 12) * 10);
        }
        
    
        .swiper-slide {
            height: 50vh;
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            transition: all 300ms linear;   
        }
          
        .swiper-slide img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
            filter: brightness(.25); 
            transition: all 300ms linear;   

            &:hover{
                filter: brightness(.5); 
                cursor: pointer;
            }
        }

        .swiper-slide-active{
            img{
                transform: scale(1.1);
                filter: brightness(.75); 

                &:hover{
                    filter: brightness(.75); 
                    cursor: initial;
                }
            }
        }
    }
}

  
  
